const Loader = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <img src="/assets/somafi/loader.gif" alt="Loading..." width={'50%'} />
    </div>
  );
};

export default Loader;
