import axios from 'axios';
import { createContext, useEffect, useState } from 'react';

const ProfilContext = createContext();

export const ProfilContextProvider = ({ children }) => {
  const [profil, setProfil] = useState({});
  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const getProfil = async () => {
    try {
      setIsLoadingProfile(true);
      const profil = await axios.get('https://api.somafi.org/profil-somafi');
      setProfil(profil.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoadingProfile(false);
    }
  };
  useEffect(() => {
    getProfil();
  }, []);

  return (
    <ProfilContext.Provider value={{ getProfil, profil, isLoadingProfile }}>
      {children}
    </ProfilContext.Provider>
  );
};

export default ProfilContext;
