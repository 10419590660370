import React from "react";
import {
  Route,
  Routes,
  BrowserRouter as Router,
} from "react-router-dom";

import { ThemeProvider } from '@mui/material/styles';
import { Theme } from "./theme";

import { typographyStyles } from './styles/jss/utilities/_typography'
import { animationStyles } from './styles/jss/utilities/_animations'
import { borderStyles } from './styles/jss/utilities/_border'
import { commonStyles } from './styles/jss/utilities/_common'
import { colorStyles } from './styles/jss/utilities/_color'
import { landingStyles } from './styles/jss/utilities/_landing'
import { positioningStyles } from './styles/jss/utilities/_positionings'
import { shadowStyles } from './styles/jss/utilities/_shadows'
import { spacingStyles } from './styles/jss/utilities/_spacing'
import { variableStyles } from './styles/jss/_variables'

import { Suspense, lazy } from 'react';
import SignIn from "./pages/SignIn";
import Loader from "./components/common/Loader";
import { SnackbarProvider } from "notistack";

function App() {
  const Landing9 = lazy(() => import('./pages/Landing'));
  const ProjectPage = lazy(() => import('./pages/ProjectPage'));

  return (
    <ThemeProvider theme={Theme}>
      {variableStyles()}
      {typographyStyles}
      {animationStyles}
      {borderStyles}
      {commonStyles}
      {colorStyles}
      {landingStyles}
      {positioningStyles}
      {shadowStyles}
      {spacingStyles}
      <Router>
        <SnackbarProvider>
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path="/" element={<Landing9 />} />
              <Route path="/projects" element={<ProjectPage />} />
              <Route path="/admin-somafi" element={<SignIn />} />
            </Routes>
          </Suspense>
        </SnackbarProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
