import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { unregister } from './serviceWorker';
import { AuthProvider } from './context/AuthContext';
import { ProfilContextProvider } from './context/ProfilContext';

ReactDOM.createRoot(document.getElementById('root')).render(
  <AuthProvider>
    <ProfilContextProvider>
      <App />
    </ProfilContextProvider>
  </AuthProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
