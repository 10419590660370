import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    token: localStorage.getItem('access_token') || '',
    role: localStorage.getItem('role') || '',
  });

  useEffect(() => {
    const fetchProfile = async () => {
      if (authState.token) {
        try {
          const {
            data: { role },
          } = await axios.get('https://api.somafi.org/auth/profile', {
            headers: {
              Authorization: `Bearer ${authState.token}`,
            },
          });
          setAuthState((prevState) => ({ ...prevState, role }));
          localStorage.setItem('role', role);
        } catch (error) {
          console.error('Failed to fetch profile', error);
        }
      }
    };
    fetchProfile();
  }, [authState.token]);

  const signIn = async (email, password) => {
    try {
      const {
        data: { access_token },
      } = await axios.post('https://api.somafi.org/auth/login', {
        email,
        password,
      });
      localStorage.setItem('access_token', access_token);
      setAuthState((prevState) => ({ ...prevState, token: access_token }));
      return true;
    } catch (error) {
      console.error('Sign in failed', error);
      return false;
    }
  };

  const signOut = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('role');
    setAuthState({ token: '', role: '' });
  };

  return (
    <AuthContext.Provider
      value={{
        isConnected: Boolean(authState.token && authState.role),
        authState,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
